module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.11_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Acre","short_name":"Acre","start_url":"/","background_color":"#F3E5C1","theme_color":"#F3E5C1","display":"standalone","icon":"src/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c47530d9da02e9593fd213152538e7f5"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.12.11_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.3.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
